<template>

  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Header class="header fixed-top"></Header>
    <Hero></Hero>
    <!-- <NominationPools></NominationPools> -->

    <main id="main">
      <!-- <About id="about" class="about"></About> -->
      <!-- <Metapay></Metapay> -->
      <Features></Features>
      <Contact id="contact" class="contact"></Contact>
    </main>

    <Footer></Footer>

  </div>

</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Header from './components/Header.vue'
import Hero from './components/Hero.vue'
// import About from './components/About.vue'
// import NominationPools from './components/NominationPools.vue'
// import Metapay from './components/Metapay.vue'
import Features from './components/Features.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    // HelloWorld,
    Header,
    Hero,
    // NominationPools,
    // About,
    // Metapay,
    Features,
    Contact,
    Footer
  }
}
</script>

<!--
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
-->