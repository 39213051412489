import Vue from 'vue'
import App from './App.vue'

// import VueRouter from 'vue-router'
// Vue.use(VueRouter)

import 'bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';

import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  components: {
    // Swiper, SwiperSlide
  },
  // setup() {
  //   const onSwiper = (swiper) => {
  //     console.log(swiper);
  //   };
  //   const onSlideChange = () => {
  //     console.log('slide change');
  //   };
  //   return {
  //     onSwiper,
  //     onSlideChange,
  //   };
  // },
  created () {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    })

  },
}).$mount('#app')
