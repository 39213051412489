<template>
    <nav id="navbar" class="navbar" :class="{'navbar-mobile !important': mobileNav}">
        <ul>
          <!-- <li><a class="nav-link scrollto active" href="#hero">Home</a></li> -->
          <li><a class="nav-link scrollto active" @click="goNav('hero')">Home</a></li>
          <!-- <li><a class="nav-link scrollto" @click="goNav('staking')">Staking</a></li> -->
          <!-- <li><a class="nav-link scrollto" @click="goNav('metapay')">met🌐pay</a></li> -->
          <li><a class="nav-link scrollto" @click="goNav('services')">Services</a></li>
          <!-- <li><a class="nav-link scrollto" href="#services">Services</a></li> -->
          <!-- <li><a class="nav-link scrollto" href="#portfolio">Portfolio</a></li> -->
          <!-- <li><a class="nav-link scrollto" href="#team">Team</a></li> -->
          <!-- <li><a href="blog.html">Blog</a></li> -->
          <!--
          <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li>
          -->
          <!-- <li><a class="nav-link scrollto" href="#contact">Contact</a></li> -->
          <li><a class="nav-link scrollto" @click="goNav('contact')">Contact</a></li>
          <!-- <li><a class="getstarted scrollto" href="#about">Get Started</a></li> -->
        </ul>
        <i class="bi mobile-nav-toggle" @click="toggleNav" 
            :class="{'bi-x': mobileNav, 'bi-list': !mobileNav}"></i>
      </nav><!-- .navbar -->
</template>

<script>
export default {
    name: "Navbar",
    // setup() {
    // },
    data() {
      return {
        mobileNav: false
      }
    },
    methods: {
      scrollTo(el) {
        let header = document.getElementById('header')
        let offset = header.offsetHeight

        if (!header.classList.contains('header-scrolled')) {
          offset -= 10
        }

        let elementPos = document.getElementById(el).offsetTop
        window.scrollTo({
          top: elementPos - offset,
          behavior: 'smooth'
        })
      },
      goNav(link) {
        // console.log("goNav", link)
        this.mobileNav = false
        this.scrollTo(link)
      },
      toggleNav() {
        this.mobileNav = !this.mobileNav
        // console.log("mobileNav", this.mobileNav)
      }
    },
}
</script>

<style scoped>
.scrollto {
  cursor: pointer;
}
.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
}
</style>