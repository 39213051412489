<template>

  <header id="header" class="header fixed-top" :class="{'header-scrolled':scrolled}">

    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

      <!-- <router-link to="/" class="logo d-flex align-items-center">
      </router-link> -->
      <a href="/" class="logo d-flex align-items-center text-decoration-none">
        <!-- <img src="assets/img/logo.png" alt=""> -->
        <img src="assets/icons/globe.png" alt="">
       <!-- <i class="bi bi-globe" style="font-size: 1.5rem"></i>&nbsp; -->
        <span>metaspan</span>
      </a>

      <navbar></navbar><!-- .navbar -->

    </div>
  </header><!-- End Header -->

</template>

<script>
import Navbar from './Navbar.vue'
import debounce from 'lodash/debounce';

export default {
    name: 'Header',
    components: {Navbar},
    // setup() {
    // },
    data() {
      return {
        scrolled: false,
      }
    },
    methods: {
      handleScroll() {
        // console.log("handleScroll", event)
        if (window.scrollY > 100) {
          this.scrolled = true
        } else {
          this.scrolled = false
        }
      }
    },
    created() {
      // window.addEventListener('scroll', this.handleDebouncedScroll);
      this.handleScrollDebounce = debounce(this.handleScroll, 75)
      window.addEventListener('scroll', this.handleScrollDebounce);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScrollDebounce);
    }
}
</script>